/* You can add global styles to this file, and also import other style files */
@import "riocan-theme.scss";

@font-face {
  font-family: "AlergiaWide";
  src: local("AlergiaWide"), url(./assets/fonts/AlergiaWide-Medium.woff) format("truetype");
}
@font-face {
  font-family: "AlergiaWide-Light";
  src: local("AlergiaWide-Light"), url(./assets/fonts/AlergiaWide-Light.woff) format("truetype");
}
@font-face {
  font-family: "AlergiaWide-Black";
  src: local("AlergiaWide-Black"), url(./assets/fonts/AlergiaWide-Black.woff) format("truetype");
}
@font-face {
  font-family: "AlergiaWide-Bold";
  src: local("AlergiaWide-Bold"), url(./assets/fonts/AlergiaWide-Bold.woff) format("truetype");
}
@font-face {
  font-family: "AlergiaWide-Medium";
  src: local("AlergiaWide-Medium"), url(./assets/fonts/AlergiaWide-Medium.woff) format("truetype");
}

$breakpoint-mobile: 580px;
$breakpoint-tablet: 768px;

html, body { height: 100%; }
body { margin: 0; font-family: "AlergiaWide-Light" !important; font-size: 16px; }

h1 {
  text-transform: uppercase;
  font-family: "AlergiaWide-Black" !important;
}
h2, h3 {
  text-transform: uppercase;
  font-family: "AlergiaWide-Bold" !important;
}
button {
  font-family: "AlergiaWide-Bold" !important;
}
table {
  font-family: "AlergiaWide-Light" !important;
}
table th {
  font-family: "AlergiaWide-Black" !important;
  // font-weight: 700 !important;
  color: map-get($map: $rioblack-palette, $key: 500) !important;
}
tr.mat-header-row {
  height: 66px !important;
}
tr.mat-row, tr.mat-footer-row {
  height: 58px !important;
}
.table-scroll-800, .table-scroll-600 {
  overflow-y: auto;
}
.table-scroll-800 {
  table {
    min-width: 800px;
  }
}
.table-scroll-600 {
  table {
    min-width: 600px;
  }
}
.table-scroll-y {
  overflow-y: auto;
  max-height: 350px;
}
.table-link {
  color: map-get($map: $rioblack-palette, $key: 500);
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-decoration: underline !important;
  cursor: pointer;
  font-family: "AlergiaWide-Bold" !important;
  &:hover
  {
    color: map-get($map: $rioblack-palette, $key: 500);
  }
}
mat-card, mat-list-option, mat-select, mat-input, mat-label, mat-checkbox, mat-paginator, mat-list-item {
  font-family: "AlergiaWide-Light" !important;
}
button.nav-btn {
  font-family: "AlergiaWide-Medium" !important;
}
button.mat-menu-item, button.btn-white {
  font-family: "AlergiaWide-Light" !important;
}
.sticky-header {
  //position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  background: #fff;
}
.breadcrumb-wrapper {
  display: flex;
  justify-content: flex-end;
}
.breadcrumb-item {
  a {
    color: map-get($map: $riored-palette, $key: 500);
  }
}
.breadcrumb-item.active {
  color: map-get($map: $rioblack-palette, $key: 500);
  &::before {
    color: map-get($map: $rioblack-palette, $key: 500);
  }
}
.add-new-button {
  background: url(assets/images/plus-icon.svg);
  background-repeat: no-repeat;
  background-position: left;
  min-height: 50px;
  background-size: contain;
  padding: 10px 20px 10px 70px !important;
  border-radius: 0 !important;
  color: map-get($map:$riored-palette , $key:500 );
  text-transform: uppercase;
}
.service-request-button {
  background: url(assets/images/service-req.svg);
  background-repeat: no-repeat;
  background-position: left;
  min-height: 50px;
  background-size: contain;
  padding: 10px 30px 10px 90px !important;
  border-radius: 0 !important;
  color: map-get($map:$rioblack-palette , $key:500 );
  text-transform: uppercase;
  background-color: #fff;
  min-width: 260px !important;
  margin-right: 20px !important;
  text-align: left !important;
  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 10px !important;
  }
}
.invoice-details-button {
  background: url(assets/images/pay-svgrepo-com.svg);
  background-repeat: no-repeat;
  background-position: left;
  min-height: 50px;
  background-size: contain;
  padding: 10px 30px 10px 90px !important;
  border-radius: 0 !important;
  color: map-get($map:$rioblack-palette , $key:500 );
  text-transform: capitalize;
  background-color: #fff;
  min-width: 260px !important;
  text-align: left !important;
}
.view-all-link {
  color: map-get($map:$riored-palette , $key:500 );
  padding: 10px 15px;
  text-transform: capitalize;
}
.sr-stats-box {
  height: 150px;
  display: inline-block;
  background: #f2f2f2;
  padding: 30px 20px;
  text-align: center;
}
.sr-red {
  background: map-get($map:$riored-palette , $key:500 );
}
.sr-grey {
  background: map-get($map:$rioblack-palette , $key:300 );
}
.sr-black {
  background: map-get($map:$rioblack-palette , $key:400 );
}
.sr-stats-amount {
  font-size: 70px;
  min-height: 70px;
  padding-top: 20px;
  color: #ffffff;
}
.sr-stats-text {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  margin-top: 5px;
}
.sr-stats-amount-invoice {
  font-size: 30px;
  min-height: 50px;
  padding-top: 20px;
  color: #ffffff;
}
.sr-stats-text-invoice {
  width: 100%;
  color: #ffffff;
  font-size: 20px;
  margin-top: 5px;
}
.sr-title-wrapper .mat-headline {
  text-transform: uppercase;
}
.cancel-button {
  width: 150px;
  height: 50px;
  border: 1px solid !important;
  border-color: map-get($map:$riored-palette , $key:500 );
  color: map-get($map:$riored-palette , $key:500 );
  text-transform: uppercase;
  border-radius: 0 !important;
  margin-right: 20px !important;
  font-family: "AlergiaWide-Light" !important;
  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }
}
.submit-button {
  width: 150px;
  height: 50px;
  background: map-get($map:$riored-palette , $key:500 );
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 0 !important;
  font-family: "AlergiaWide-Light" !important;
  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 10px !important;
  }
}
.submit-small-button {
  height: 40px;
  background: map-get($map:$riored-palette , $key:500 );
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 0 !important;
  font-family: "AlergiaWide-Light" !important;
  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 10px !important;
  }
}
.cancel-small-button {
  height: 40px;
  border: 1px solid !important;
  border-color: map-get($map:$riored-palette , $key:500 ) !important;
  color: map-get($map:$riored-palette , $key:500 ) !important;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 0 !important;
  font-family: "AlergiaWide-Light" !important;
  margin-right: 20px !important;
  font-family: "AlergiaWide-Light" !important;
  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }
}
.btn-w-100 {
  width: 100px;
}
.upload-button, .Add-button {
  border-radius: 0 !important;
  font-family: "AlergiaWide-Light" !important;
}
mat-card-content {
  font-family: "AlergiaWide-Light" !important;
}
.news-date {
  color: map-get($map:$riored-palette , $key:500 );
}
.news-title {
  font-size: 20px !important;
}
.news-item-image {
  height: 220px;
  object-fit: cover;
}
.news-description {
  line-height: 1.4;
  font-size: 16px;
}
.search-filter {
  background-color: #ffffff;
  background-image: url(assets/images/search-icon.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 200px !important;
  background-size: 15px !important;
  height: 35px;
  width: 230px !important;
  padding: 0 0 0 10px !important;
  border: none;
  border-radius: 5px;
}
.service-search .search-filter {
  background-color: #ffffff;
  border: 1px solid #b3b3b3;
}
.favourite-news {
  background-image: url(assets/images/star.svg);
  background-repeat: no-repeat;
  background-size: 50px;
  height: 50px;
  width: 50px;
  position: absolute;
  top: -20px;
  left: 10px;
}
.view-all-news {
  color: map-get($map:$riored-palette , $key:500) !important;
  padding: 0 10px !important;
  text-transform: uppercase;
  &:hover .mat-button-focus-overlay
  {
    opacity: 0 !important;
  }
}
.override-view-btn {
  padding: 0 !important;
  line-height: 20px !important;
}
mat-paginator {
  width: 100%;
  background: transparent !important;
}
.mat-card-content {
  line-height: 1.6;
  font-size: 16px;
  min-height: 160px;
}
.mat-card-content-home {
  line-height: 1.6;
  font-size: 16px;
  height: 190px;
}
.news-imag-link {
  cursor: pointer;
}
::ng-deep .mat-radio-label {
  margin-bottom: 0 !important;
}
.select-property-wrapper {
  background: map-get($map: $rioblack-palette, $key: 20);
}
.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}
.property-location {
  text-transform: uppercase;
  margin-bottom: 0 !important;
}
.property-name {
  font-size: 16px;
  margin-bottom: 0 !important;
}
.select-property-wrapper {
  display: flex;
  align-items: center;
}
.property-floor, .property-suite {
  font-size: 16px;
  margin-bottom: 0 !important;
  font-weight: bold;
}
::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: map-get($map: $riored-palette, $key: 500) !important;
}
::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle,
::ng-deep .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
::ng-deep .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: map-get($map: $riored-palette, $key: 500) !important;
}
.table-red-btn {
  background: map-get($map: $riored-palette, $key: 500);
  min-width: 50px !important;
  height: 40px;
  color: #ffffff;
  border-radius: 0 !important;
}
.seperator {
  display: block;
  width: 100%;
  height: 5px;
  background-color: map-get($map: $riored-palette , $key: 500);
}
.text-editor {
  min-height: 300px;
  background: #F0F0F0;
}
.mat-form-field.ng-touched {
  margin-bottom: 10px;
}
.form-error {
  margin-bottom: 10px;
}
.expand-icon {
  height: 26px;
  margin-left: 5px;
}

/* 404 styles */
.pnf-text {
  font-size: 160px;
  color: map-get($map: $riored-palette , $key: 500);
  display: block;
  line-height: 130px;
}


// Table
table {
  width: 100%;
}
td {
  height: 60px;
  vertical-align: top;
  padding-bottom: 20px !important;
}
.table-scroll-600 {
  table {
    td{
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}
.sr-export-button {
  color: map-get($map:$riored-palette , $key:500 );
}
.sr-table-header {
  background: map-get($map:$rioblack-palette, $key:20 );
}
.sr-bottom-border-remove {
  border-bottom: 0 !important;
}


.upload-document-wrapper {
  border: 1px solid #b3b3b3;
}


// Raio buttons
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: map-get($map:$riored-palette , $key:500 );
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: map-get($map:$riored-palette , $key:500 );
}

.building-gray-icon {
  color: map-get($map: $rioblack-palette, $key: 200);
  cursor: pointer;
}
.status-label {
  width: 100px;
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 5px;
  display: block;
  text-align: center;
}
.mat-radio-label {
  margin-bottom: 0 !important;
}
